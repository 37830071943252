import { Button, Code, Heading, Text } from "@chakra-ui/react";
import { Link as GatsbyLink } from "gatsby";
import React from "react";
import { Helmet } from "react-helmet";
import Layout from "../components/Layout";

const Index = () =>
  <Layout>
    <Helmet>
      <title>Documentation</title>
    </Helmet>

    <Heading>Getting Started</Heading>

    <Text>Let's say you currently have a <Code>Dockerfile</Code> that looks something like this:</Text>

    <Code display="block" whiteSpace="pre" children={`FROM mcr.microsoft.com/dotnet/aspnet:6.0
...`}/>

    <Text>After you create your free account, then you can subscribe to image updates for <Code>dotnet/aspnet:6.0</Code>.</Text>
    
    <Text>When you create your subscription, you'll get an email telling you to change your <Code>dotnet/aspnet:6.0</Code> reference to <Code>dotnet/aspnet@sha256:060d945252e26eb3baa53c95912ce1a0515df664ab0a22bdd293a87ea98203de</Code>. This locks your <Code>Dockerfile</Code> to a specific release of the ASP.NET Core 6.0 base image.</Text>

    <Text>So, you would check into source control an updated <Code>Dockerfile</Code> that looks like this:</Text>

    <Code display="block" whiteSpace="pre" children={`FROM mcr.microsoft.com/dotnet/aspnet@sha256:060d945252e26eb3baa53c95912ce1a0515df664ab0a22bdd293a87ea98203de # dotnet/aspnet:6.0
...`}/>

    <Heading>Handling Update Notifications</Heading>

    <Text>When <Code>dotnet/aspnet:6.0</Code> is updated (with Debian security updates or .NET Core runtime patches), image-watch.com will send you an email notifying you of the update, and telling you to change <Code>dotnet/aspnet@sha256:060d945252e26eb3baa53c95912ce1a0515df664ab0a22bdd293a87ea98203de</Code> to <Code>dotnet/aspnet@sha256:478b2a393451006d8c17ba3fb5858e40f077df795c768b8e813b6117c0efe41c</Code>.</Text>

    <Text>Because these are unique hash strings, you can apply this across your entire repository using a basic text replacement.</Text>

    <Text>Now you have an updated <Code>Dockerfile</Code> that you can check into source control with a commit message like "Updated dotnet/aspnet":</Text>

    <Code display="block" whiteSpace="pre" children={`FROM mcr.microsoft.com/dotnet/aspnet@sha256:478b2a393451006d8c17ba3fb5858e40f077df795c768b8e813b6117c0efe41c # dotnet/aspnet:6.0
...`}/>

    <Text>That's it! Now your images can stay up-to-date, and your base image updates also exist in your source control as explicit commits.</Text>

    <Text>Tip: the emails we send also have a JSON file attachment, if you want to automate the text replacement and commit / pull request.</Text>

    <Button as={GatsbyLink} to="/app">Go to subscriptions</Button>

  </Layout>;

export default Index;